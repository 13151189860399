<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="ticket-history-header align" slot="header">
      <h4 class="ft-primary">{{ $t("Details") }}</h4>
      <h3 class="ft-primary ticket-id" v-if="ticketInfo">
        {{ ticketInfo.requestid }}
      </h3>
    </div>

    <div slot="component">
      <div class="loading-sec" v-if="isTicketLoading">
        <Loading></Loading>
      </div>
      <div v-else>
        <div class="ticket-history-content">
          <div class="align">
            <p class="ticket-title">
              <span class="ft-primary ft-medium">{{ $t("title") }}</span
              >&nbsp;
              <span class="ft-secondary" v-if="ticketInfo">{{ ticketInfo.title.toUpperCase() }}</span>
            </p>
            <p class="ft-secondary" v-if="ticketInfo">
              {{ displayDateFormat(ticketInfo.created) }}
            </p>
          </div>
          <div>
            <p>
              <span class="ft-primary ft-medium">{{ $t("category") }}</span
              >&nbsp;
              <span class="ft-secondary" v-if="ticketInfo">{{ getCategoryName(ticketInfo.category) }}</span>
            </p>
            <!-- <p class="ft-primary ft-medium">Description:</p>
          <p class="ft-secondary">
            Hove you ever finally just gave in to the temptation and read your
            horoscope gave in to the temptation.
          </p> -->
          </div>
        </div>
        <div class="ticket-history-section" v-if="ticketInfo">
          <p class="ticket-history-section-title">{{ $t("COMMENTS") }}</p>
          <div class="ticket-history-section-contain">
            <div class="ticket-history-section-description" v-for="(item, i) in ticketInfo.messageData" :key="i">
              <div class="align">
                <p class="ft-primary ft-medium">{{ item.updatedbyname }}</p>
                &nbsp;
                <p class="ft-secondary">
                  {{ displayDateFormat(item.messagedate) }}
                </p>
              </div>
              <p class="ft-secondary">{{ item.message }}</p>
              <div class="item-docs" v-if="item.documents">
                <div class="item-docs-image" v-for="(img, i) in item.documents" :key="i">
                  <img @click="redirectTo(img)" :src="img.fileurl" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group form-error">
          <div class="error-container" v-if="formError">
            <p class="error">{{ formError }}</p>
          </div>
        </div>
        <div class="ticket-history-footer">
          <div v-if="ticketInfo.requeststatus !== 'CLOSED'" class="ticket-history-footer-align">
            <input type="text" class="input" v-model="ticketComment" :placeholder="$t('Add Comment')" />
            <div
              class="new-comment"
              :class="{
                default: localDisplayLang === 'eng' ? true : false,
                flipped: localDisplayLang === 'ara' ? true : false,
              }"
            >
              <input
                type="file"
                class="file-input"
                value=""
                @change="attachFile($event)"
                accept="image/jpeg, image/png"
              />
              <img src="@/assets/icons/interface.svg" alt />
            </div>
            <button
              class="button-primary send"
              :class="[
                providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button',

                localDisplayLang === 'eng' ? 'default' : 'flipped',
              ]"
              @click="updateTicket"
            >
              <span class="btn-txt-color">{{ $t("SUBMIT") }}</span>
            </button>
            <!-- <div v-if="showLoader">
              <Loading></Loading>
            </div> -->
          </div>
          <!-- <div
            class="ticket-history-footer-attachments"
            v-if="imagesUploadedArr.length !== 0"
          >
            <div
              class="ticket-history-footer-attachments-item"
              v-for="(file, index) in imagesUploadedArr"
              :key="index"
            >
              <div class="ticket-history-footer-attachments-item-filename">
                {{ file.name }}
              </div>
              <div
                class="ticket-history-footer-attachments-item-delete"
                @click="deleteAttachment(file)"
              >
                <img src="@/assets/icons/ClosePopup.svg" alt />
              </div>
            </div>
          </div> -->

          <div class="tickets-attachments" v-if="imagesUploadedArr.length !== 0">
            <div class="tickets-attachments-file" v-for="(image, index) in imagesUploadedArr" :key="index">
              <div class="tickets-attachments-file-details">
                <!-- <div class="tickets-attachments-file-details-image">
                <img :src="image.pic" alt />
              </div> -->
                <div class="tickets-attachments-file-details-name">
                  <span class="tickets-attachments-file-name">{{ image.name }}</span>
                </div>
              </div>
              <img class="delete-file" src="@/assets/icons/ClosePopup.svg" @click="deleteAttachment(image)" alt />
            </div>
          </div>

          <div v-if="showLoader">
            <Loading></Loading>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { mapGetters } from "vuex";
import { _providerId } from "@/provider-config.js";

export default {
  props: {
    closePopup: {
      type: Function,
    },
    ticket: {
      type: Object,
    },
    selectedTab: {
      type: String,
    },
  },
  data() {
    return {
      width: "40%",
      margin: "5% auto",
      radiusType: "full",
      ticketInfo: null,
      ticketComment: "",
      fileAttachments: [],
      showLoader: false,
      formError: null,
      providerUniqueId: _providerId,
      isTicketLoading: false,
      localDisplayLang: null,
      imagesUploadedArr: [],
      attachmentedFiles: [],
    };
  },

  watch: {
    fileAttachments() {},
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 2000);
      }
    },

    attachmentedFiles(val) {
      if (val && val.length > 0) {
        if (this.imagesUploadedArr.length === val.length) {
          let payload = {
            details: {
              message: this.ticketComment,
            },
            requestid: this.ticketInfo.requestid,
          };

          if (this.attachmentedFiles.length) {
            payload.details.filename = JSON.stringify(this.attachmentedFiles);
          }

          console.log("The files are attached successfully, Payload ===>", payload);
          // this.createTicket(true);
          eventBus.$emit("update-current-ticket", payload);
          this.showLoader = false;
        }
      }
    },
  },

  computed: {
    ...mapGetters(["appConfig"]),
  },

  created() {
    console.log("WHAT IS TICKET -->", this.ticket);

    this.isTicketLoading = true;
    this.imagesUploadedArr = [];
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    let payload = {
      requestid: this.ticket.requestid,
    };

    eventBus.$emit("fetch-ticket", payload);
  },

  mounted() {
    console.log("TICKET HISTORY COMPONENT MOUNT", this.ticket);

    eventBus.$on("get-ticket-response", (data) => {
      if (!data.data.errorcode && !data.data.reason) {
        this.ticketInfo = data.data;
        this.isTicketLoading = false;
      } else {
        this.isTicketLoading = false;
        this.$emit("dismissPopup");
      }
    });

    eventBus.$on("update-Ticket-response", (response) => {
      if (response.success) {
        this.$emit("refreshList");
        this.closePopup();
      }
    });
  },

  methods: {
    redirectTo(image) {
      window.open(image.fileurl, "_self");
    },

    deleteAttachment(value) {
      let getIndex = this.imagesUploadedArr.findIndex((el) => el.name === value.name);
      console.log("Delete file index", getIndex);
      if (getIndex > -1) {
        this.imagesUploadedArr.splice(getIndex, 1);
      }
    },

    uploadImageAttachments() {
      if (this.imagesUploadedArr && this.imagesUploadedArr.length > 0) {
        console.log("The image file upload func is called");

        this.imagesUploadedArr.forEach((image, index) => {
          let fileObj = {
            isTicket: true,
            index: index,
            params: {
              filename: image,
            },
          };
          console.log(`The ${index} upload call ---> `, fileObj);

          eventBus.$emit("fileUpload", fileObj);

          eventBus.$on(`file-upload-response-${index}`, (data) => {
            this.attachmentedFiles.push(data.success);
            console.log("the response for " + index + " ----->", data);
            eventBus.$off(`file-upload-response-${index}`);
          });
        });
      }
    },

    updateTicket() {
      if (!this.validateMessage(this.ticketComment)) {
        return;
      }

      this.showLoader = true;

      let payload = {
        details: {
          message: this.ticketComment,
        },
        requestid: this.ticketInfo.requestid,
      };

      if (this.imagesUploadedArr && this.imagesUploadedArr.length > 0) {
        console.log("Image is present, hence calling image upload first");
        this.uploadImageAttachments();
      } else {
        console.log("Image is not present, hence calling create directly");
        eventBus.$emit("update-current-ticket", payload);
        this.showLoader = false;
      }
    },

    hasWhiteSpace(s) {},

    validateMessage(value) {
      this.formError = null;

      const newValue = value.trim();

      if (!newValue) {
        this.formError = this.$t("Comment Required");
        return false;
      }
      return true;
    },
  },
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mixins: [Utility],

  beforeDestroy() {
    eventBus.$off("get-ticket-response");
    eventBus.$off("update-Ticket-response");
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticket-title {
  word-break: break-all;
  width: 65%;
}

.loading-sec {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tickets {
  &-attachments {
    margin-top: 1.2rem;
    &-file {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0rem;
      border-bottom: 1px solid $clr-dark-gd4;
      &-details {
        display: flex;
        align-items: center;
        &-image {
          width: 50px;
          height: 25px;
          img {
            width: 100%;
            height: 25px;
          }
        }
        &-name {
          font-family: $font-regular;
          font-size: 0.8rem;
          color: $clr-light-gd3;
          word-break: break-all;
          padding-left: 0.4rem;
        }
      }
      .delete-file {
        opacity: 0.5;
        width: 12px;
        margin-left: 10px;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.ft-primary {
  color: #efeff4;
  font-family: $font-regular;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
}
.ft-secondary {
  color: #efeff4;
  opacity: 0.5;
  font-family: $font-regular;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}
.ft-medium {
  font-size: 16px;
}
.ticket-id {
  font-size: 16px;
  margin: 0px 35px;
}
.ticket-history {
  &-header {
    padding: 4% 8%;
    background-color: #212121;
    border-radius: 14px 14px 0 0;
    opacity: 0.95;
  }
  &-content {
    padding: 4% 8% 0%;
  }
  &-section {
    padding: 4% 8% 0%;
    margin-top: 10px;
    &-title {
      opacity: 0.5;
      color: #efeff4;
      font-family: $font-regular;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.17px;
      line-height: 20px;
      margin-bottom: 10px;
    }
    &-contain {
      background: rgba(0, 0, 0, 0.8);
      border-radius: 5px;
      max-height: 400px;
      overflow-y: auto;
    }
    &-description {
      margin: 20px;
      border-bottom: 1px solid $clr-dark-gd4;
      // background: rgba(0, 0, 0, 0.8);
      // border-radius: 5px;
      .item-docs {
        // display: grid;
        // grid-template-columns: repeat(5, 1fr);
        display: flex;
        flex-flow: row wrap;
        padding: 0.5rem 0rem 1rem 0rem;
        &-image {
          width: 66px;
          height: 39px;
          margin: 8px;
          img {
            width: 100%;
            height: 39px;
            cursor: pointer;
          }
        }
      }
    }
  }
  &-footer {
    padding: 4% 8%;
    border-radius: 5px;
    &-align {
      display: flex;
      justify-content: space-between;
      // position: relative;
      .input {
        margin: 0;
        background-color: $clr-dark-gd6;
        border: 1px solid $clr-dark-gd6;
        border-top-left-radius: 0.4rem;
        border-bottom-left-radius: 0.4rem;
        &:focus {
          border-top-left-radius: 0.4rem;
          border-bottom-left-radius: 0.4rem;
        }
      }
      .new-comment {
        position: relative;
        width: 50px;
        background-color: $clr-dark-gd6;
        border-bottom-right-radius: 0.4rem;

        &.default {
          // border-top-right-radius: 0.4rem;
          // border-radius: 0.4rem 0px 0px 0.4rem; /* top left, top right, bottom right, bottom left */
          border-radius: 0 0.4rem 0.4rem 0;
        }

        &.flipped {
          // border-top-left-radius: 0.4rem;
          border-radius: 0.4rem 0px 0px 0.4rem; /* top left, top right, bottom right, bottom left */
          // border-radius: 0 0.4rem 0.4rem 0;
        }

        img {
          position: absolute;
          top: 24%;
          width: 18px;
          height: 18px;
          opacity: 0.5;
          cursor: pointer;
        }
        .file-input {
          position: absolute;
          top: 24%;
          opacity: 0;
          z-index: 100;
          border-radius: 5em;
          width: 18px;
          height: 18px;
          &:focus {
            background-color: none;
          }
        }
      }
      .send {
        width: unset;
        // margin-left: 1rem;
        // margin-right: 1rem;
        padding: 0.5rem 1.6rem;

        &.default {
          margin-left: 1rem;
          margin-right: 0rem !important;
        }

        &.flipped {
          margin-right: 1rem;
          margin-left: 0rem !important;
        }
      }
    }
    // input {
    //   height: 100%;
    //   margin-right: 10px;
    // }
    // .send {
    //   padding: 0.5rem 1.6rem;
    // }
    &-attachments {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1rem;
      margin-top: 1rem;
      &-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0.4rem;
        border: 1px solid $clr-dark-gd10;
        border-radius: 1rem;
        &:hover {
          border: 1px solid $btn-clr;
          border-style: dashed;
          cursor: pointer;
        }
        &-filename {
          color: $clr-dark-gd10;
          font-size: 0.8rem;
        }
        &-delete {
          color: $clr-dark-gd10;
          margin-left: 1rem;
          width: 15px;
          height: 15px;
          background-color: rgba(239, 239, 244, 0.1);
          border-radius: 50%;
          position: relative;
          &:hover {
            img {
              opacity: 1;
            }
          }
          img {
            position: absolute;
            opacity: 0.7;
            top: 22%;
            left: 22%;
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }
}
.form-error {
  width: 80%;
  margin: 1rem auto 0rem;
}

@media only screen and (max-width: 576px) {
  .align {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ft-primary {
    color: #efeff4;
    font-family: $font-regular;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
  }
  .ft-secondary {
    color: #efeff4;
    opacity: 0.5;
    font-family: $font-regular;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .ft-medium {
    font-size: 14px;
  }

  .ticket-history-section-title {
    font-size: 14px;
  }

  .send {
    width: unset;
    padding: 0.5rem 1.6rem;

    &.default {
      margin-left: 1rem;
      margin-right: 0rem !important;
    }

    &.flipped {
      margin-right: 1rem;
      margin-left: 0rem !important;
    }
  }

  .ticket-history {
    &-content {
      padding: 2% 4% 0%;
    }
    &-section {
      padding: 2% 4% 0%;
      margin-top: 10px;

      &-contain {
        max-height: 250px;
      }
    }
    &-footer {
      padding: 4%;
    }
  }
}
</style>
